@font-face {
	font-family: "kfont";
	src: url('../fonts/kfont.eot');
	src: url('../fonts/kfont.eot?#iefix') format('eot'),
	url('../fonts/kfont.woff2') format('woff2'),
	url('../fonts/kfont.woff') format('woff'),
	url('../fonts/kfont.ttf') format('truetype'),
	url('../fonts/kfont.svg#kfont') format('svg');
	font-weight: normal;
    font-style: normal;
    font-display: block;
}

body .icon-bottom:before,
body .icon-left:before,
body .icon-right:before,
body .icon-top:before,
body .icon-address:before,
body .icon-arrow-bottom:before,
body .icon-arrow-bottom2:before,
body .icon-arrow-bottom3:before,
body .icon-arrow-left:before,
body .icon-arrow-left2:before,
body .icon-arrow-left3:before,
body .icon-arrow-right:before,
body .icon-arrow-right2:before,
body .icon-arrow-right3:before,
body .icon-arrow-top:before,
body .icon-arrow-top2:before,
body .icon-arrow-top3:before,
body .icon-ascenseurs:before,
body .icon-cart:before,
body .icon-check:before,
body .icon-citation1:before,
body .icon-citation2:before,
body .icon-cles:before,
body .icon-commande:before,
body .icon-delete:before,
body .icon-devis:before,
body .icon-doc:before,
body .icon-email:before,
body .icon-entretien:before,
body .icon-euro:before,
body .icon-eye:before,
body .icon-facebook:before,
body .icon-forme:before,
body .icon-grid:before,
body .icon-heart:before,
body .icon-history:before,
body .icon-hours:before,
body .icon-information:before,
body .icon-instagram:before,
body .icon-installation:before,
body .icon-linkedin:before,
body .icon-livraison:before,
body .icon-logo3:before,
body .icon-mail:before,
body .icon-main-address:before,
body .icon-map:before,
body .icon-map3:before,
body .icon-map4:before,
body .icon-mapmarker:before,
body .icon-moins:before,
body .icon-monte-escalier:before,
body .icon-new-address:before,
body .icon-phone:before,
body .icon-pinterest:before,
body .icon-plan:before,
body .icon-plateformes:before,
body .icon-plus:before,
body .icon-pouce:before,
body .icon-rdv:before,
body .icon-realisations:before,
body .icon-schedule:before,
body .icon-services:before,
body .icon-star:before,
body .icon-table:before,
body .icon-twitter:before,
body .icon-user:before,
body .icon-voucher:before,
body .icon-wishlist:before,
body .icon-youtube:before,
body .icon-youtube2:before,
body .icon-3d:before,
body .icon-picto-logo:before,
body .icon-checkbox:before,
body .icon-arrow:before,
body .icon-logo4:before,
body .icon-pattern-02:before,
body .icon-arrow-left4:before,
body .icon-arrow-right4:before,
body .icon-search:before {
  font-family: "kfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
}

body .icon-bottom:before { content: "\EA0A" }
body .icon-left:before { content: "\EA0B" }
body .icon-right:before { content: "\EA0C" }
body .icon-top:before { content: "\EA0D" }
body .icon-address:before { content: "\EA0F" }
body .icon-arrow-bottom:before { content: "\EA10" }
body .icon-arrow-bottom2:before { content: "\EA11" }
body .icon-arrow-bottom3:before { content: "\EA12" }
body .icon-arrow-left:before { content: "\EA13" }
body .icon-arrow-left2:before { content: "\EA14" }
body .icon-arrow-left3:before { content: "\EA15" }
body .icon-arrow-right:before { content: "\EA16" }
body .icon-arrow-right2:before { content: "\EA17" }
body .icon-arrow-right3:before { content: "\EA18" }
body .icon-arrow-top:before { content: "\EA19" }
body .icon-arrow-top2:before { content: "\EA1A" }
body .icon-arrow-top3:before { content: "\EA1B" }
body .icon-ascenseurs:before { content: "\EA1C" }
body .icon-cart:before { content: "\EA1D" }
body .icon-check:before { content: "\EA1E" }
body .icon-citation1:before { content: "\EA1F" }
body .icon-citation2:before { content: "\EA20" }
body .icon-cles:before { content: "\EA21" }
body .icon-commande:before { content: "\EA22" }
body .icon-delete:before { content: "\EA23" }
body .icon-devis:before { content: "\EA24" }
body .icon-doc:before { content: "\EA25" }
body .icon-email:before { content: "\EA26" }
body .icon-entretien:before { content: "\EA27" }
body .icon-euro:before { content: "\EA28" }
body .icon-eye:before { content: "\EA29" }
body .icon-facebook:before { content: "\EA2A" }
body .icon-forme:before { content: "\EA2B" }
body .icon-grid:before { content: "\EA2C" }
body .icon-heart:before { content: "\EA2D" }
body .icon-history:before { content: "\EA2E" }
body .icon-hours:before { content: "\EA2F" }
body .icon-information:before { content: "\EA30" }
body .icon-instagram:before { content: "\EA31" }
body .icon-installation:before { content: "\EA32" }
body .icon-linkedin:before { content: "\EA33" }
body .icon-livraison:before { content: "\EA34" }
body .icon-logo3:before { content: "\EA35" }
body .icon-mail:before { content: "\EA36" }
body .icon-main-address:before { content: "\EA37" }
body .icon-map:before { content: "\EA38" }
body .icon-map3:before { content: "\EA39" }
body .icon-map4:before { content: "\EA3A" }
body .icon-mapmarker:before { content: "\EA3B" }
body .icon-moins:before { content: "\EA3C" }
body .icon-monte-escalier:before { content: "\EA3D" }
body .icon-new-address:before { content: "\EA3E" }
body .icon-phone:before { content: "\EA3F" }
body .icon-pinterest:before { content: "\EA40" }
body .icon-plan:before { content: "\EA41" }
body .icon-plateformes:before { content: "\EA42" }
body .icon-plus:before { content: "\EA43" }
body .icon-pouce:before { content: "\EA44" }
body .icon-rdv:before { content: "\EA45" }
body .icon-realisations:before { content: "\EA46" }
body .icon-schedule:before { content: "\EA47" }
body .icon-services:before { content: "\EA48" }
body .icon-star:before { content: "\EA49" }
body .icon-table:before { content: "\EA4A" }
body .icon-twitter:before { content: "\EA4B" }
body .icon-user:before { content: "\EA4C" }
body .icon-voucher:before { content: "\EA4D" }
body .icon-wishlist:before { content: "\EA4E" }
body .icon-youtube:before { content: "\EA4F" }
body .icon-youtube2:before { content: "\EA50" }
body .icon-3d:before { content: "\EA51" }
body .icon-picto-logo:before { content: "\EA52" }
body .icon-checkbox:before { content: "\EA53" }
body .icon-arrow:before { content: "\EA54" }
body .icon-logo4:before { content: "\EA55" }
body .icon-pattern-02:before { content: "\EA56" }
body .icon-arrow-left4:before { content: "\EA57" }
body .icon-arrow-right4:before { content: "\EA58" }
body .icon-search:before { content: "\EA59" }
